(() => {
  document.addEventListener('DOMContentLoaded', () => {
    var acc = document.getElementsByClassName("custom-accordion");
    var i;

    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener("click", function () {

        this.classList.toggle("active");
      });
    }
  });
})()

